@charset "UTF-8";

// import Open Sans fonts
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,400,300,500,600,700&subset=latin,latin-ext);

// settings and vars
@import "variables.less";

// vendors
@import "vendor/bootstrap/bootstrap.min.less";
@import "vendor/linearicons/linearicons.less";
@import "vendor/font-awesome/font-awesome.less";
@import "vendor/animate/animate.min.less";

@import "vendor/customscrollbar/jquery.mCustomScrollbar.min.less";
@import "vendor/bootstrap-select/bootstrap-select.less";
@import "vendor/select2/select2.less";
@import "vendor/bootstrap-datetimepicker/bootstrap-datetimepicker-build.less";
@import "vendor/datatables/dataTables.bootstrap.less";
@import "vendor/summernote/summernote.less";
@import "vendor/sweetalert/sweetalert.less";
@import "vendor/dropzone/dropzone.less";
@import "vendor/nestable/nestable.less";
@import "vendor/jvectormap/jquery-jvectormap.less";
@import "vendor/codemirror/codemirror.less";
@import "vendor/syntaxhighlighter/shCoreDefault.less";
@import "vendor/morris/morris.less";
@import "vendor/rickshaw/rickshaw.less";
@import "vendor/cropper/cropper.less";
@import "vendor/fullcalendar/fullcalendar.less";
@import "vendor/bootstrap-tagsinput/bootstrap-tagsinput.less";
@import "vendor/bootstrap-daterange/daterangepicker.less";
@import "vendor/bootstrap-tour/bootstrap-tour.less";
@import "vendor/multiselect/multi-select.less";

@import "vendor/ionslider/ion.rangeSlider.less";
@import "vendor/ionslider/ion.rangeSlider.boooya.less";

@import "vendor/bootstrap-colorpicker/bootstrap-colorpicker.less";
@import "vendor/xeditable/bootstrap-editable.less";

// import components
@import "helpers/mixins.less";
@import "helpers/classes.less";

@import "components/app-layout.less";
@import "components/app-header.less";
@import "components/app-footer.less";

@import "components/app-navigation.less";

@import "components/app-typography.less";
@import "components/app-form-elements.less";

@import "components/app-checkbox-radio.less";
@import "components/app-switch-button.less";

@import "components/app-buttons.less";

@import "components/app-elements.less";
@import "components/app-blocks-panels.less";
@import "components/app-alerts.less";
@import "components/app-tabs.less";
@import "components/app-popups.less";
@import "components/app-accordion.less";
@import "components/app-labels-badges.less";
@import "components/app-tables.less";
@import "components/app-tiles.less";
@import "components/app-lists.less";
@import "components/app-contacts.less";
@import "components/app-features.less";
@import "components/app-loading.less";
@import "components/app-tips.less";
@import "components/app-statusbar.less";
@import "components/app-spinners.less";
@import "components/app-widgets.less";
@import "components/app-progressbars.less";
@import "components/app-faq.less";
@import "components/app-login.less";
@import "components/app-messages.less";
@import "components/app-blog.less";
@import "components/app-timeline.less";
@import "components/app-wizard.less";
@import "components/app-payment.less";
@import "components/app-other.less";

// rtl mode
@import "components/rtl/rtl.less";

@import "custom.less";