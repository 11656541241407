    .app-faq{
        float: left;
        width: 100%;
                
        .app-faq-item{
            float: left;
            width: 100%;            
            margin-bottom: 10px;
            background: @app-desing-base-element-bg;
            border: 1px solid @app-desing-border-color;            
            .border-radius();            
            .transition(background @app-default-transition-speed linear);
            
            .app-faq-item-title{
                float: left;
                width: 100%;
                padding: 15px 15px 15px 45px;                
                line-height: 20px;
                font-size: @app-layout-base-font-size;
                cursor: pointer;
                font-weight: 600;
                position: relative;
                
                &:after{
                    position: absolute;
                    content: "\e888";
                    font-family: 'linearicons';
                    left: 15px;
                    top: 15px;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 20px;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    color: @app-design-main-color;
                }
            }
            .app-faq-item-content{
                float: left;
                width: 100%;  
                padding: 0px;                
                max-height: 0px;
                overflow: hidden;
                
                .app-faq-highlight{
                    color: @app-design-success-bg;
                    text-decoration: underline;
                }
            }
            
            &.open{
                background: darken(@app-desing-base-element-bg,2%);                
                
                .app-faq-item-title{
                    &:after{                        
                        content: "\e887";
                    }
                }
                
                .app-faq-item-content{
                    padding: 0px 15px 15px;                    
                    max-height: 500px;
                }                
            }
            
            &:hover{
                background: @app-desing-base-element-bg-hover;
            }
            
            &:last-child{
                margin-bottom: 0px;
            }
        }
    }