.wizard{
    float: left;
    width: 100%;
    margin-bottom: 20px;

    .anchor{
        width: 100%;
        float: left; 
        padding: 0px; 
        margin: 0px; 
        list-style: none; 
        display: block; 
        position: relative; 

        &:after{
            content: " "; 
            position: absolute; 
            bottom: 0px; 
            left: 0px; 
            width: 100%; 
            height: 3px;
            background: @app-desing-border-color; 
            z-index: 1;
            .border-radius(1px);
        }

        li{
            float: left; 
            display: block;

            a{
                color: @app-layout-base-font-color-heading; 
                text-decoration: none; 
                display: block;
                padding: 10px 20px 30px 0px; 
                position: relative;
                font-size: 16px;

                .stepNumber{display: none;}
                .stepDesc{
                    font-size: @app-layout-base-font-size - 1;
                    font-weight: 600;
                    line-height: 20px;
                    text-transform: uppercase;

                    small{
                        color: @app-layout-base-font-color-secondary; 
                        font-size: @app-layout-base-font-size - 2;
                        font-weight: 600;
                        line-height: 20px;                        
                    }
                }

                &:before{
                    content: " "; 
                    position: absolute; 
                    bottom: 0px; 
                    left: 0px; 
                    width: 100%; 
                    height: 3px; 
                    background: @app-desing-border-color; 
                    z-index: 2;                         
                }

                &:after{
                    content: " ";
                    position: absolute;
                    bottom: -15px;
                    left: 0px;
                    width: 35px;
                    height: 35px;
                    background: #FFF;
                    border: 3px solid @app-desing-border-color;
                    z-index: 3;
                    text-align: center;
                    line-height: 28px;
                    color: @app-layout-base-font-color-heading;
                    .border-radius(3px);
                }

                &.done{
                    &:after{
                        border-color: @app-design-success-bg; 
                        background: lighten(@app-design-success-bg,5%);
                        color: @app-design-success-color;
                        content: "\f00c"; 
                        font-family: FontAwesome;                        
                    }
                    &:before{
                        .horizontal-gradient(@app-design-success-bg,@app-design-primary-bg);
                    }
                    &.active{
                        &:before{                            
                            .horizontal-gradient(@app-design-success-bg,@app-design-success-bg);
                        }
                    }
                }
                &.error{
                    &:after{
                        border-color: @app-design-danger-bg; 
                        background: lighten(@app-design-danger-bg,5%); 
                        content: "\f00d"; 
                        font-family: FontAwesome; 
                        color: @app-design-danger-color; 
                    }
                    &:before{
                        background: @app-design-danger-bg;
                    }
                }

                &.selected{
                    &:after{
                        border-color: @app-design-primary-bg;
                        background: lighten(@app-design-primary-bg,5%);
                        content: "\f0da"; 
                        font-family: FontAwesome; 
                        color: @app-design-primary-color;
                        padding-left: 2px;
                        border-width: 3px;
                        line-height: 28px;
                    }
                    &:before{                        
                        .horizontal-gradient(@app-design-primary-bg,@app-desing-border-color);
                    }
                    &.active{
                        &:after{
                            border-color: @app-design-success-bg;
                            background: @app-design-success-bg;
                            color: @app-design-success-color;                            
                            content: "\f00c";
                            padding-left: 0px;
                        }
                        &:before{
                            .horizontal-gradient(@app-design-success-bg,@app-design-success-bg);
                        }
                    }
                }

            }
        }

        &.steps_1 li{width: 100%;}
        &.steps_2 li{width: 50%;}
        &.steps_3 li{width: 33.333333%;}
        &.steps_4 li{width: 25%;}
        &.steps_5 li{width: 20%;}
        &.steps_6 li{width: 16.666666%;}
        &.steps_7 li{width: 14.285714%;}
        &.steps_8 li{width: 12.5%;}

    }

    .stepContainer, .actionBar{
        float: left; 
        width: 100%; 
        margin-top: 50px;
    }

    .actionBar{
        margin-top: 20px;

        .btn.pull-right{margin-left: 5px;}
        .btn-primary{display: none;}
        .loader{display: none;}
    }

}
