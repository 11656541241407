.app{
    
    .app-blog-important-block{
        margin: 0px;
        float: left;
        width: 100%;
        margin-bottom: 20px;
        .box-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
        
        > div[class^='col-']{
            padding: 0px;
        }
        
        .tile-basic{
            margin-bottom: 0px;
        }
    }
    .app-blog-blocks{
        margin-left: -10px;
        margin-right: -10px;        
        margin-bottom: 20px;
        
        > div[class^='col-']{
            padding-left: 10px;
            padding-right: 10px;
        }
        
        .tile-basic{
            //.box-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
        }
    }    
    .app-blog-banner{
        float: left;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }
}