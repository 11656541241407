@import "_bootstrap-datetimepicker.less";

//this is here so the compiler doesn't complain about a missing bootstrap mixin
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
