#lma {
  .app-navigation {

    .app-navigation-logo {
      background-image: url(/img/logo_LMA_h60.png);
      &:hover {
        background: url(/img/logo_LMA_h60.png);
      }
    }

    &.app-navigation-minimized {
      .app-navigation-logo {
        background-image: url(/img/logo_LMA_h60_sm.png);
      }
    }

  }
}
#lmna {
  .app-navigation {

    .app-navigation-logo {
      background-image: url(/img/logo_LMNA_h60.png);
      &:hover {
        background: url(/img/logo_LMNA_h60.png);
      }
    }

    &.app-navigation-minimized {
      .app-navigation-logo {
        background-image: url(/img/logo_LMNA_h60_sm.png);
      }
    }

  }
}
#lmpc {
  .app-navigation {

    .app-navigation-logo {
      background-image: url(/img/logo_LMPC_h60.png);
      &:hover {
        background: url(/img/logo_LMPC_h60.png);
      }
    }

    &.app-navigation-minimized {
      .app-navigation-logo {
        background-image: url(/img/logo_LMPC_h60_sm.png);
      }
    }

  }
}

#lmf {
  .app-navigation {

    .app-navigation-logo {
      background-image: url(/img/logo_LMF_h60.png);
      &:hover {
        background: url(/img/logo_LMF_h60.png);
      }
    }

    &.app-navigation-minimized {
      .app-navigation-logo {
        background-image: url(/img/logo_LMF_h60_sm.png);
      }
    }

  }
}

.app .app-container .app-sidebar {
  width: 270px;
}

.app .app-container .app-content.app-sidebar-left {
  padding-left: 270px;
}

@media (max-width: 992px) {
  .app .app-container .app-content.app-sidebar-left {
    padding-left: 0;
  }

  .app .app-container .app-sidebar {
    width: 100%;
  }
}

.label-nocturne, .badge-nocturne {
  background-color: #681c85;
  color: white;
}

.app .app-content-tabs > ul > li > a.active {
  color: @app-layout-base-font-color;
}