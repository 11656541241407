.icons-preview{
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: block;
    width: 100%;
    text-align: center;
    
    li{
        float: none;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid @app-desing-border-color;       
        margin: 0px 1px 4px 0px;        
        cursor: pointer;
        background: @app-desing-base-element-bg;
        .transition(background @app-default-transition-speed linear);
        
        span[class^='icon-'],span.fa{
            line-height: 40px;
            font-size: 20px;
        }
        span.fa{
            font-size: 14px;
        }
        
        span.name{
            display: none;
        }
        
        &:hover{
            background: @app-desing-base-element-bg-hover;
        }
    }
    
    &.extended{
        
        li{
            width: 100px;
            height: 100px;
            padding: 5px;
            margin: 0px 1px 0px 0px;
            overflow: hidden;
            
            span[class^='icon-'], span.fa{
                line-height: 60px;
                font-size: 40px;
                display: block;
                float: left;
                width: 100%;                
            }
            
            span.fa{
                font-size: 28px;
            }
            
            span.name{
                display: block;
                float: left;
                width: 100%;
                font-size: 11px;
                font-weight: 600;
                line-height: 14px;
            }   
        }
        
    }
}

.modal-icon-preview-icon{
    span{
        &[class^="icon-"]{
            font-size: 60px;
        }
        &.fa{
            font-size: 42px;
        }
    }
}

.dropdown,.dropup{
    &.dropdown-example{
        > .dropdown-menu{
            position: static;
            display: block;
            margin-bottom: 1px;
            clear: left;
        }
    }
}

.app-chart-wrapper{
    float: left;
    width: 100%;
    position: relative;    
    

    &.app-chart-with-axis{
        padding-left: 20px;
        padding-bottom: 20px;
        
        .app-chart-yaxis{            
            position: absolute;
            left: 0px;
            height: 100%;
            width: 20px;                        
        }
        .app-chart-xaxis{
            position: absolute;
            width: 100%;
            height: 21px;
            left: 20px;
            bottom: 0px;                        
        }
               
        .rickshaw_graph .y_ticks path, 
        .rickshaw_graph .x_ticks_d3 path{
            stroke: #DDD;
        }
        .detail .x_label{
            display: none !important;
        }
    }
}

.simple-code-sample{    
    float: left;
    width: 100%;
    background: #F5F5F5;
    color: #333;
    padding: 15px 20px;
    line-height: 25px;
    font-weight: 600;
    .border-radius();
}

/* chart holder */
.app-chart-holder{
    float: left;
    width: 100%;
}

.chart-legend{
    position: absolute; 
    top: 5px; 
    right: 5px;    
}
.rickshaw_legend{
    background: rgba(255,255,255,0.4);
}
.rickshaw_legend .label{
    color: #000;
}
/* chart holder */

/* sparkline */    
    .jqstooltip{display: block !important; padding: 0px !important; border: 0px !important;}
    .jqsfield{padding: 4px 5px 5px; display: inline-block; line-height: 20px;}
/* eof sparkline */              

.doc_fix_nav{
    position: relative;
    
    &.fixed{
        position: fixed;        
        top: 15px;        
        width: 100%;
        
        &.stack{
            top: auto;
            bottom: 392px;
        }
    }    
}